<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" style="margin-top: -4em;">
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-tooltip v-model="showTooltip" activator="#MetadadosCampoDinamico-tipo_acao" left max-width="100" nudge-left="10">
      <span>{{ $t('message.selecione_tipo_acao_para_pesquisar') }}</span>
    </v-tooltip>

    <apuracao-contrato-cancelar-lote-filtros
      v-model="abrirFiltro"
      @ApuracaoContratoCancelarLoteFiltros__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoCancelarLoteFiltros_conteinerCarregado="abrirTooltip">
    </apuracao-contrato-cancelar-lote-filtros>

    <v-container pa-0 mt-3 fluid v-resize="onResize" :style="`height: ${alturaCorpo}px; overflow-y: auto;`">
      <v-row>
        <v-expansion-panel v-for="(contrato, i) in contratos"
            :key="i">
          <v-expansion-panel-header>
            <v-checkbox
                v-model="contrato.selecionado"
                hide-details
                class="shrink pt-0 mt-0"
                @click.stop="(e) => selecionarContrato(contrato, e)">
              </v-checkbox>
              <span class="subheading">{{`${contrato.idContrato} :: ${contrato.origemContrato}`}}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <apuracao-contrato-cancelar-lote-tabela
              :id-contrato="contrato.idContrato"
              :filtros="filtros"
              @ApuracaoContratoCancelarLoteTabela__recebimentoSemValor="contratoComRecebimentoSemValor(contrato)">
            </apuracao-contrato-cancelar-lote-tabela>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-row>

      <p v-if="mostrarNaoEncontrado" class="text-xs-center">{{ $t('label.tabela_sem_conteudo') }}</p>

      <v-row id="ApuracaoContratoCancelarLote__Rodape" class="ApuracaoContratoCancelarLote__Rodape pa-2"
                :style="`width: ${tamanhoRodape}px`" :column="xs">
        <div :class="{ 'text-xs-center': xs }">
          <v-pagination
            v-if="totalPagina > 1"
            v-model="numeroPagina"
            :length="totalPagina"
            :total-visible="$vuetify.breakpoint.mdAndUp ? 9 : 5"
            @input="buscarContratos(filtros)">
          </v-pagination>
        </div>
        <v-spacer v-if="!xs"></v-spacer>
        <v-btn v-show="temApuracaoSelecionada" @click="abrirModalJustificativaCancelarSelecionadas" class="mr-2 my-auto">{{ $t('label.cancelar_selecionados') }}</v-btn>
        <v-btn color="primary" v-if="contratos.length" @click="abrirModalJustificativaCancelarTudo" class="my-auto">{{ $t('label.cancelar_tudo') }}</v-btn>
      </v-row>
    </v-container>

    <apuracao-contrato-cancelar-lote-industria-modal-justificativa
      ref="modalJustificativa"
      :title="tituloJustificativa"
      :message="mensagemJustificativa"
      @agree="callback"/>

  </div>
</template>

<script>
import ApuracaoContratoCancelarLoteFiltros from './ApuracaoContratoCancelarLoteFiltros';
import { buscarTotalApuradoPorContrato } from '../../common/resources/apuracao/apuracao-contrato';
import { getMoney } from '../../common/functions/helpers';
import ApuracaoContratoCancelarLoteTabela from './ApuracaoContratoCancelarLoteTabela';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import ApuracaoContratoCancelarLoteIndustriaModalJustificativa from './cancelar-lote/ApuracaoContratoCancelarLoteIndustriaModalJustificativa';

export default {
  name: 'ApuracaoContratoCancelarLote',
  components: {
    Confirm,
    ApuracaoContratoCancelarLoteTabela,
    ApuracaoContratoCancelarLoteFiltros,
    ApuracaoContratoCancelarLoteIndustriaModalJustificativa,
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      resource: this.$api.apuracaoContrato(this.$resource),
      abrirFiltro: false,
      idContrato: null,
      contratos: [],
      temApuracaoSelecionada: false,
      totalPagina: 1,
      tamanhoPagina: 10,
      numeroPagina: 1,
      filtros: {},
      tamanhoTela: window.innerWidth,
      tamanhoFiltros: 0,
      alturaTela: window.innerHeight,
      alturaRodaPe: 0,
      eventoClickCheckbox: null,
      jaBuscou: false,
      mensagemErro: '',
      fechamentoParcial: false,
      contratosCancelar: null,
      tituloJustificativa: '',
      mensagemJustificativa: '',
      callback: () => {},
      idApuracoesSelecionadas: {},
    };
  },
  watch: {
    abrirFiltro(val) {
      if (!val) {
        this.showTooltip = false;
      }
    },
  },
  computed: {
    alturaCorpo() {
      return this.alturaTela - 150 - this.alturaRodaPe;
    },
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mostrarNaoEncontrado() {
      return !this.contratos.length && this.jaBuscou;
    },
  },
  methods: {
    apuracoesSelecionadas(apuracoes, idContrato) {
      const apuracoesSelecionadas = apuracoes.map((apuracao) => apuracao.idApuracao);
      if (apuracoes && apuracoes.length) {
        this.idApuracoesSelecionadas[idContrato] = {
          apuracoesSelecionadas,
        };
      } else {
        delete this.idApuracoesSelecionadas[idContrato];
      }
      this.temApuracaoSelecionada = !!Object.keys(this.idApuracoesSelecionadas).length;
    },
    cancelarTudo(observacao) {
      this.$refs.modalJustificativa.close();
      const params = {
        idsContrato: this.contratos.map((c) => c.idContrato),
        observacao,
      };
      this.cancelar(params, observacao);
    },
    cancelarSelecionados(observacao) {
      this.$refs.modalJustificativa.close();
      const idApuracoes = [];
      Object.keys(this.idApuracoesSelecionadas).forEach((id) => {
        this.idApuracoesSelecionadas[id].apuracoesSelecionadas.forEach((idApuracao) => {
          idApuracoes.push(idApuracao);
        });
      });
      const params = {
        idsApuracao: idApuracoes,
        observacao,
      };
      this.cancelar(params, observacao);
    },
    abrirModalJustificativaCancelarSelecionadas() {
      this.tituloJustificativa = this.$t('message.cancelamento_modal_titulo_selecionadas');
      this.mensagemJustificativa = this.$t('message.cancelamento_modal_mensagem_selecionadas');
      this.callback = this.cancelarSelecionados;
      this.$refs.modalJustificativa.open();
    },
    abrirModalJustificativaCancelarTudo() {
      this.tituloJustificativa = this.$t('message.cancelamento_modal_titulo_tudo');
      this.mensagemJustificativa = this.$t('message.cancelamento_modal_mensagem_tudo');
      this.callback = this.cancelarTudo;
      this.$refs.modalJustificativa.open();
    },
    cancelar(params) {
      this.workflowApuracaoAcaoResource.cancelarLote(params)
        .then(() => {
          this.$toast(this.$t('message.apuracoes_canceladas'));
          this.aplicarFiltros(this.filtros);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    onResize() {
      this.alturaRodaPe = document.getElementById('ApuracaoContratoCancelarLote__Rodape').offsetHeight;
      this.tamanhoTela = window.innerWidth;
      this.alturaTela = window.innerHeight;
    },
    getMoney,
    aplicarFiltros(filtros) {
      this.numeroPagina = 1;
      this.contratos = [];
      this.buscarContratos(filtros);
    },
    limparArraysControladores() {
      this.acoesSelecionadas = [];
      this.temApuracaoSelecionada = false;
      this.contratosCancelar = null;
    },
    buscarContratos(filtros) {
      filtros.tamanhoPagina = this.tamanhoPagina;
      filtros.numeroPagina = this.numeroPagina;
      this.filtros = filtros;
      return buscarTotalApuradoPorContrato(filtros, this.$resource)
        .then((response) => {
          this.contratos = response.data.resposta;
          this.limparArraysControladores();
          this.totalPagina = Math.ceil(response.data.quantidadeRegistrosPagina / this.tamanhoPagina);
          this.jaBuscou = true;
        })
        .catch((err) => {
          this.$error(this, err);
          this.jaBuscou = true;
        });
    },
    conteinerCarregado() {
      this.tamanhoFiltros = document.getElementById('ApuracaoContratoCancelarLoteFiltros__filtro_rapido').offsetWidth;
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
  },
  mounted() {
    this.alturaRodaPe = document.getElementById('ApuracaoContratoCancelarLote__Rodape').offsetHeight;
    this.abrirFiltro = true;
  },
};
</script>

<style scoped>
  .ApuracaoContratoCancelarLote__Rodape {
    display: flex;

    position: fixed;
    z-index: 2;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
  }
</style>
