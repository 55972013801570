<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth">
    <v-card>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text v-html="message || messageDialog"></v-card-text>
        <v-textarea
          style="padding-left:15px;padding-right:15px"
          id="justificativa_obrigatoria"
          name="justificativa-obrigatoria"
          :label="`${$tc('label.justificativa', 1)} *`"
          v-model="justificativa"
          auto-grow
          autofocus
          :maxlength="250"
          :counter="250"
          rows="1"
          :rules="[rules.required]"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="nao" color="primary darken-1" v-if="!hideDisagree" text @click.native="disagree">
            {{disagreeLabel ? disagreeLabel : $t('label.nao')}}
          </v-btn>
          <v-btn id="sim" color="primary darken-1" text @click.native="agree">
            {{agreeLabel ? agreeLabel : $t('label.sim')}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApuracaoContratoCancelarLoteIndustriaModalJustificativa',
  props: {
    title: String,
    message: String,
    persistent: false,
    agreeLabel: String,
    disagreeLabel: String,
    cancelLabel: String,
    maxWidth: {
      type: String,
      default: '590',
    },
    hideDisagree: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleDialog: '',
      messageDialog: '',
      dialog: false,
      callback: null,
      justificativa: '',
      valid: true,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open() {
      this.titleDialog = this.title;
      if (!this.title || !this.title.length) {
        this.titleDialog = this.$t('title.atencao');
      }
      this.dialog = true;
    },
    openWithParams(title, message, callback) {
      this.titleDialog = title;
      this.messageDialog = message;
      this.callback = callback;
      this.dialog = true;
    },
    disagree() {
      this.close();
      this.justificativa = '';
      this.$emit('disagree');
      if (this.callback) {
        this.callback(false);
      }
    },
    agree() {
      if (!this.$refs.form.validate()) return;
      this.close();
      this.$emit('agree', this.justificativa);
      if (this.callback) {
        this.callback(true);
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
